import { FC, ReactNode, useEffect } from "react";
import { IntlProvider } from "react-intl";
import messagesEn from "src/i18n/en.json";
import messagesJa from "src/i18n/ja.json";

interface AppIntlProviderProps {
  children: ReactNode;
}

interface TranslationString {
  [key: string]: string;
}

interface Translations {
  [key: string]: TranslationString;
}

const AppIntlProvider: FC<AppIntlProviderProps> = ({ children }) => {
  const currentLocale = navigator.language.split(/[-_]/)[0];

  const messages: Translations = {
    en: messagesEn,
    ja: messagesJa,
  };

  useEffect(() => {
    document.documentElement.lang = currentLocale;
  }, [currentLocale]);

  return (
    <IntlProvider locale={currentLocale} messages={messages[currentLocale] ?? messages.en} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
